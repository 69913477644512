import { Link } from "gatsby"
import React from "react"


// Importing custom inputs
import { FormRowSingle,
         FormRowDouble,
         FormInnTitle,
         FormInnSect,
         InputCheck,
         InputRadio,
         InputSingle } from "../common/forms/inputParts"


const FormContact = () => (
  <FormInnSect>
    <FormRowDouble>
      <InputSingle Holder="First Name" className="input--lg" InputName="firstname" />
      <InputSingle Holder="Last Name" className="input--lg" InputName="lastname" />
    </FormRowDouble>
    <FormRowSingle>
      <InputSingle Holder="Email Address" className="input--lg" InputName="email" />
    </FormRowSingle>
    <FormRowSingle>
      <div className="input--area">
        <textarea name="message" placeholder="Example: type of company you are/not interested in, blog URL, etc..."></textarea>
      </div>
    </FormRowSingle>

    <button className="btn btn--lg btn--prim fw" type="submit" data-hover="Submit Information">Submit Information</button>
  </FormInnSect>
)


const FormSectFoot = () => (
  <FormInnSect>
  </FormInnSect>
)

const ContactForm = () => (
  <form className="form__cont form--explore" name="contact" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="contact" />

    <div className="form__inn">
      <FormContact />
    </div>
  </form>
)



export default ContactForm
