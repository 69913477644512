import * as React from 'react';



// Single Form Row
const FormRowSingle = (props) => {
  const Class = `form__row--single ` + (props.className ? props.className : '')
  return (
    <div className={Class}>{props.children}</div>
  )
}


// Double Form Row
const FormRowDouble = (props) => {
  const Class = `form__row--double ` + (props.className ? props.className : '')
  return (
    <div className={Class}>{props.children}</div>
  )
}


// Form Title with two passable props
const FormInnTitle = (props) => {
  const Class = `form__inn__title ` + (props.className ? props.className : '')

  return (
    <div className={Class}>
      <a href={`#` + props.titleAnchor} className="form__inn__anch">{`#` + props.titleAnchorOrder}</a>
      <span className="form__inn__title__text">{props.titleText}</span>
    </div>
  )
}


// Form Inner Sect
const FormInnSect = (props) => {
  return (
    <div className="form__sect" id={props.sectAnchor}>
      {props.children}
    </div>
  )
}

const InputRadio = (props) => {
  return (
    <div className="input--radio">
      <input type="radio" name={props.radioName} id={props.radioId} value={props.radioLabel} />
      <label htmlFor={props.radioId}>{props.radioLabel}</label> 
    </div>
  )
}

const InputCheck = (props) => {
  return (
    <div className="input--checkbox">
      <input type="checkbox" name={props.checkName} id={props.checkId} value={props.checkLabel} />
      <label htmlFor={props.checkId}>{props.checkLabel}</label> 
    </div>
  )
}

const InputSingle = (props) => {
  const Class = `input ` + (props.className ? props.className : '')
  const Holder = `Enter text ( Default Place Holder )` + (props.Holder ? props.Holder : '')
  return (
    <div className={Class}>
      <input type="text" placeholder={props.Holder} name={props.InputName} />
      {props.children}
    </div>
  )
}

export {
  InputCheck,
  InputRadio,
  FormRowSingle,
  FormRowDouble,
  FormInnTitle,
  FormInnSect,
  InputSingle
}