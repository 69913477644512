import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import NavInn from "../components/inner/navInn"
import SEO from "../components/seo"
import Hero from "../components/common/hero"
import Btn from "../components/common/button"
import ContactForm from "../components/forms/contactForm"

// Importing Contentful Components
import ContactHeader from "./contactHeader"


const ContactPage = () => (
  <Layout>
    <SEO title="Contact us" />
    <Hero className="hero--inn hero--contact">
      <div className="cont--spaced">
        <div className="hero__head">
          <div className="hero__logo__cont">
            <a href="/" className="hero__logo">PrimeSet</a>
          </div>
          <NavInn className="" />
          {/* <Btn btnTitle="Explore" className="btn--prim" Link btnLink="/explore" /> */}
        </div>
        <div className="hero__content">
          <ContactHeader />
          <div className="hero__content--sep"></div>
        </div>
      </div>
    </Hero>

    <section className="sect__cont sect--contact">
      <div className="cont--spaced">
        <ContactForm />
      </div>
    </section>
  </Layout>
)

export default ContactPage
